<template>
  <validation-observer ref="validationObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Konfiguracja</h4>
        <b-form-group label="Język" label-for="v-language-select">
          <v-select
            id="v-language-select"
            label="value"
            placeholder="Wybierz język"
            v-model="form.lang"
            :clearable="false"
            :deselectFromDropdown="false"
            :options="languages"
            @input="fetchByLocale" />
        </b-form-group>
        <b-form-group label="Tytuł" label-for="v-title">
          <validation-provider name="Tytuł" rules="required|max:150" v-slot="{ errors }">
            <b-form-input id="v-image-title" placeholder="Tytuł" v-model="form.title" :disabled="!form.lang" />
            <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <hr />

      <b-col class="mt-4">
        <h4>Element 1</h4>

        <b-form-group label="Tytuł 1 elementu" label-for="v-title-1">
          <validation-provider name="Tytuł 1 elementu" rules="required|max:150" v-slot="{ errors }">
            <b-form-input
              id="v-title-1"
              placeholder="Tytuł 1 elementu"
              v-model="form.element_1.title"
              :disabled="!form.lang" />
            <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Opis 1 elementu" label-for="v-description-1">
          <validation-provider name="Opis 1 elementu" rules="required|max:150" v-slot="{ errors }">
            <b-form-input
              id="v-description-1"
              placeholder="Opis 1 elementu"
              v-model="form.element_1.description"
              :disabled="!form.lang" />
            <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox v-model="form.element_1.is_external_link"> Link do zewnętrznej strony? </b-form-checkbox>
        </b-form-group>

        <b-form-group label="Link 1 elementu" label-for="v-link-1">
          <validation-provider name="Link 1 elementu" rules="required|max:150" v-slot="{ errors }">
            <b-form-input
              id="v-link-1"
              placeholder="Link 1 elementu"
              v-model="form.element_1.link"
              :disabled="!form.lang" />
            <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Zdjęcie 1 elementu" label-for="v-image">
          <b-button @click="toggleShowModal('element_1_image')" :disabled="!form.lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie 1 elementu" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input
              v-if="files.element_1_image"
              type="hidden"
              name="Zdjęcie 1 elementu"
              v-model="files.element_1_image.file" />
            <input v-else type="hidden" name="Zdjęcie 1 elementu" v-model="files.element_1_image" />

            <ImageSelector
              v-if="showImagesModal.element_1_image"
              :files="filesArray"
              :show-modal="showImagesModal.element_1_image"
              @closeModal="toggleShowModal('element_1_image')"
              @submit="(fileList) => handleFile(fileList[0], 'element_1_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.element_1_image"
            :image="files.element_1_image"
            @deleteImage="() => handleDelete('element_1_image')" />
        </b-form-group>
      </b-col>

      <b-col class="mt-4">
        <h4>Element 2</h4>
        <b-form-group label="Tytuł 2 elementu" label-for="v-title-2">
          <validation-provider name="Tytuł 2 elementu" rules="required|max:150" v-slot="{ errors }">
            <b-form-input
              id="v-title-2"
              placeholder="Tytuł 2 elementu"
              v-model="form.element_2.title"
              :disabled="!form.lang" />
            <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Opis 2 elementu" label-for="v-description-2">
          <validation-provider name="Opis 2 elementu" rules="required|max:150" v-slot="{ errors }">
            <b-form-input
              id="v-description-2"
              placeholder="Opis 2 elementu"
              v-model="form.element_2.description"
              :disabled="!form.lang" />
            <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox v-model="form.element_2.is_external_link"> Link do zewnętrznej strony? </b-form-checkbox>
        </b-form-group>

        <b-form-group label="Link 2 elementu" label-for="v-link-2">
          <validation-provider name="Link 2 elementu" rules="required|max:150" v-slot="{ errors }">
            <b-form-input
              id="v-link-2"
              placeholder="Link 2 elementu"
              v-model="form.element_2.link"
              :disabled="!form.lang" />
            <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Zdjęcie 2 elementu" label-for="v-image">
          <b-button @click="toggleShowModal('element_2_image')" :disabled="!form.lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie 2 elementu" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input
              v-if="files.element_2_image"
              type="hidden"
              name="Zdjęcie 2 elementu"
              v-model="files.element_2_image.file" />
            <input v-else type="hidden" name="Zdjęcie 2 elementu" v-model="files.element_2_image" />

            <ImageSelector
              v-if="showImagesModal.element_2_image"
              :files="filesArray"
              :show-modal="showImagesModal.element_2_image"
              @closeModal="toggleShowModal('element_2_image')"
              @submit="(fileList) => handleFile(fileList[0], 'element_2_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.element_2_image"
            :image="files.element_2_image"
            @deleteImage="() => handleDelete('element_2_image')" />
        </b-form-group>
      </b-col>

      <b-col class="mt-4">
        <h4>Element 3</h4>

        <b-form-group label="Tytuł 3 elementu" label-for="v-title-3">
          <validation-provider name="Tytuł 3 elementu" rules="required|max:150" v-slot="{ errors }">
            <b-form-input
              id="v-title-3"
              placeholder="Tytuł 3 elementu"
              v-model="form.element_3.title"
              :disabled="!form.lang" />
            <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Opis 3 elementu" label-for="v-description-3">
          <validation-provider name="Opis 3 elementu" rules="required|max:150" v-slot="{ errors }">
            <b-form-input
              id="v-description-3"
              placeholder="Opis 3 elementu"
              v-model="form.element_3.description"
              :disabled="!form.lang" />
            <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox v-model="form.element_3.is_external_link"> Link do zewnętrznej strony? </b-form-checkbox>
        </b-form-group>

        <b-form-group label="Link 3 elementu" label-for="v-link-3">
          <validation-provider name="Link 3 elementu" rules="required|max:150" v-slot="{ errors }">
            <b-form-input
              id="v-link-3"
              placeholder="Link 3 elementu"
              v-model="form.element_3.link"
              :disabled="!form.lang" />
            <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Zdjęcie 3 elementu" label-for="v-image">
          <b-button @click="toggleShowModal('element_3_image')" :disabled="!form.lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie 3 elementu" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input
              v-if="files.element_3_image"
              type="hidden"
              name="Zdjęcie 3 elementu"
              v-model="files.element_3_image.file" />
            <input v-else type="hidden" name="Zdjęcie 3 elementu" v-model="files.element_3_image" />

            <ImageSelector
              v-if="showImagesModal.element_3_image"
              :files="filesArray"
              :show-modal="showImagesModal.element_3_image"
              @closeModal="toggleShowModal('element_3_image')"
              @submit="(fileList) => handleFile(fileList[0], 'element_3_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.element_3_image"
            :image="files.element_3_image"
            @deleteImage="() => handleDelete('element_3_image')" />
        </b-form-group>
      </b-col>

      <b-col class="mt-5">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          :disabled="!this.form.lang.lang">
          Zapisz
        </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';

import FileUploadMixin from '@/mixins/FileUploadMixin.vue';
import NotificationMixin from '@/mixins/NotificationMixin.vue';
import { toolbarSettings } from '@/constants/quill-editor';
import { getLanguages } from '@/constants/languages';

import ImageSelector from '@/components/shared/ImageSelector.vue';
import ImagePreview from '@/components/shared/ImagePreview.vue';

export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  mixins: [FileUploadMixin, NotificationMixin],
  components: {
    quillEditor,
    ImageSelector,
    ImagePreview,
  },

  data() {
    return {
      form: this.page,
      languages: getLanguages(),
      editorOption: {
        modules: {
          toolbar: toolbarSettings,
        },
      },
    };
  },

  created() {
    this.form.lang = {
      lang: 'pl',
      value: 'Polski',
    };
    this.setFileUploadData(
      ['element_1_image', 'element_2_image', 'element_3_image'],
      ['element_1_image', 'element_2_image', 'element_3_image'],
    );
    this.fetchByLocale();
  },

  watch: {
    async page(newValue) {
      if (this.form.value === newValue) return;

      await this.setImage(this.page.element_1.image, 'element_1_image');
      await this.setImage(this.page.element_2.image, 'element_2_image');
      await this.setImage(this.page.element_3.image, 'element_3_image');

      const lang = this.form.lang;
      this.form = { ...newValue };
      this.form.lang = lang;
    },
  },

  methods: {
    onSubmit() {
      const data = {
        lang: this.form.lang.lang,
        title: this.form.title,
        element_1_title: this.form.element_1.title,
        element_1_description: this.form.element_1.description,
        element_1_link: this.form.element_1.link,
        element_1_link_is_external: this.form.element_1.is_external_link,
        element_2_title: this.form.element_2.title,
        element_2_description: this.form.element_2.description,
        element_2_link: this.form.element_2.link,
        element_2_link_is_external: this.form.element_2.is_external_link,
        element_3_title: this.form.element_3.title,
        element_3_description: this.form.element_3.description,
        element_3_link: this.form.element_3.link,
        element_3_link_is_external: this.form.element_3.is_external_link,
        element_1_image: this.files.element_1_image,
        element_2_image: this.files.element_2_image,
        element_3_image: this.files.element_3_image,
      };

      this.$emit('onSubmit', data);
    },
    validationForm() {
      this.$refs.validationObserver.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }

        this.showFormValidationNotification();
      });
    },
    fetchByLocale() {
      this.$emit('fetchByLocale', this.form.lang.lang);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
