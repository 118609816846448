var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validationObserver"},[_c('b-form',{staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-col',[_c('h4',{staticClass:"mb-2"},[_vm._v("Konfiguracja")]),_c('b-form-group',{attrs:{"label":"Język","label-for":"v-language-select"}},[_c('v-select',{attrs:{"id":"v-language-select","label":"value","placeholder":"Wybierz język","clearable":false,"deselectFromDropdown":false,"options":_vm.languages},on:{"input":_vm.fetchByLocale},model:{value:(_vm.form.lang),callback:function ($$v) {_vm.$set(_vm.form, "lang", $$v)},expression:"form.lang"}})],1),_c('b-form-group',{attrs:{"label":"Tytuł","label-for":"v-title"}},[_c('validation-provider',{attrs:{"name":"Tytuł","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-image-title","placeholder":"Tytuł","disabled":!_vm.form.lang},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('hr'),_c('b-col',{staticClass:"mt-4"},[_c('h4',[_vm._v("Element 1")]),_c('b-form-group',{attrs:{"label":"Tytuł 1 elementu","label-for":"v-title-1"}},[_c('validation-provider',{attrs:{"name":"Tytuł 1 elementu","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-title-1","placeholder":"Tytuł 1 elementu","disabled":!_vm.form.lang},model:{value:(_vm.form.element_1.title),callback:function ($$v) {_vm.$set(_vm.form.element_1, "title", $$v)},expression:"form.element_1.title"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-form-group',{attrs:{"label":"Opis 1 elementu","label-for":"v-description-1"}},[_c('validation-provider',{attrs:{"name":"Opis 1 elementu","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-description-1","placeholder":"Opis 1 elementu","disabled":!_vm.form.lang},model:{value:(_vm.form.element_1.description),callback:function ($$v) {_vm.$set(_vm.form.element_1, "description", $$v)},expression:"form.element_1.description"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-form-group',[_c('b-form-checkbox',{model:{value:(_vm.form.element_1.is_external_link),callback:function ($$v) {_vm.$set(_vm.form.element_1, "is_external_link", $$v)},expression:"form.element_1.is_external_link"}},[_vm._v(" Link do zewnętrznej strony? ")])],1),_c('b-form-group',{attrs:{"label":"Link 1 elementu","label-for":"v-link-1"}},[_c('validation-provider',{attrs:{"name":"Link 1 elementu","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-link-1","placeholder":"Link 1 elementu","disabled":!_vm.form.lang},model:{value:(_vm.form.element_1.link),callback:function ($$v) {_vm.$set(_vm.form.element_1, "link", $$v)},expression:"form.element_1.link"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-form-group',{attrs:{"label":"Zdjęcie 1 elementu","label-for":"v-image"}},[_c('b-button',{attrs:{"disabled":!_vm.form.lang},on:{"click":function($event){return _vm.toggleShowModal('element_1_image')}}},[_vm._v(" Wybierz zdjęcie ")]),_c('validation-provider',{attrs:{"name":"Zdjęcie 1 elementu","rules":"required|size:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.files.element_1_image)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.element_1_image.file),expression:"files.element_1_image.file"}],attrs:{"type":"hidden","name":"Zdjęcie 1 elementu"},domProps:{"value":(_vm.files.element_1_image.file)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files.element_1_image, "file", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.element_1_image),expression:"files.element_1_image"}],attrs:{"type":"hidden","name":"Zdjęcie 1 elementu"},domProps:{"value":(_vm.files.element_1_image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files, "element_1_image", $event.target.value)}}}),(_vm.showImagesModal.element_1_image)?_c('ImageSelector',{attrs:{"files":_vm.filesArray,"show-modal":_vm.showImagesModal.element_1_image},on:{"closeModal":function($event){return _vm.toggleShowModal('element_1_image')},"submit":function (fileList) { return _vm.handleFile(fileList[0], 'element_1_image'); }}}):_vm._e(),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.files.element_1_image)?_c('ImagePreview',{attrs:{"image":_vm.files.element_1_image},on:{"deleteImage":function () { return _vm.handleDelete('element_1_image'); }}}):_vm._e()],1)],1),_c('b-col',{staticClass:"mt-4"},[_c('h4',[_vm._v("Element 2")]),_c('b-form-group',{attrs:{"label":"Tytuł 2 elementu","label-for":"v-title-2"}},[_c('validation-provider',{attrs:{"name":"Tytuł 2 elementu","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-title-2","placeholder":"Tytuł 2 elementu","disabled":!_vm.form.lang},model:{value:(_vm.form.element_2.title),callback:function ($$v) {_vm.$set(_vm.form.element_2, "title", $$v)},expression:"form.element_2.title"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-form-group',{attrs:{"label":"Opis 2 elementu","label-for":"v-description-2"}},[_c('validation-provider',{attrs:{"name":"Opis 2 elementu","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-description-2","placeholder":"Opis 2 elementu","disabled":!_vm.form.lang},model:{value:(_vm.form.element_2.description),callback:function ($$v) {_vm.$set(_vm.form.element_2, "description", $$v)},expression:"form.element_2.description"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-form-group',[_c('b-form-checkbox',{model:{value:(_vm.form.element_2.is_external_link),callback:function ($$v) {_vm.$set(_vm.form.element_2, "is_external_link", $$v)},expression:"form.element_2.is_external_link"}},[_vm._v(" Link do zewnętrznej strony? ")])],1),_c('b-form-group',{attrs:{"label":"Link 2 elementu","label-for":"v-link-2"}},[_c('validation-provider',{attrs:{"name":"Link 2 elementu","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-link-2","placeholder":"Link 2 elementu","disabled":!_vm.form.lang},model:{value:(_vm.form.element_2.link),callback:function ($$v) {_vm.$set(_vm.form.element_2, "link", $$v)},expression:"form.element_2.link"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-form-group',{attrs:{"label":"Zdjęcie 2 elementu","label-for":"v-image"}},[_c('b-button',{attrs:{"disabled":!_vm.form.lang},on:{"click":function($event){return _vm.toggleShowModal('element_2_image')}}},[_vm._v(" Wybierz zdjęcie ")]),_c('validation-provider',{attrs:{"name":"Zdjęcie 2 elementu","rules":"required|size:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.files.element_2_image)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.element_2_image.file),expression:"files.element_2_image.file"}],attrs:{"type":"hidden","name":"Zdjęcie 2 elementu"},domProps:{"value":(_vm.files.element_2_image.file)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files.element_2_image, "file", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.element_2_image),expression:"files.element_2_image"}],attrs:{"type":"hidden","name":"Zdjęcie 2 elementu"},domProps:{"value":(_vm.files.element_2_image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files, "element_2_image", $event.target.value)}}}),(_vm.showImagesModal.element_2_image)?_c('ImageSelector',{attrs:{"files":_vm.filesArray,"show-modal":_vm.showImagesModal.element_2_image},on:{"closeModal":function($event){return _vm.toggleShowModal('element_2_image')},"submit":function (fileList) { return _vm.handleFile(fileList[0], 'element_2_image'); }}}):_vm._e(),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.files.element_2_image)?_c('ImagePreview',{attrs:{"image":_vm.files.element_2_image},on:{"deleteImage":function () { return _vm.handleDelete('element_2_image'); }}}):_vm._e()],1)],1),_c('b-col',{staticClass:"mt-4"},[_c('h4',[_vm._v("Element 3")]),_c('b-form-group',{attrs:{"label":"Tytuł 3 elementu","label-for":"v-title-3"}},[_c('validation-provider',{attrs:{"name":"Tytuł 3 elementu","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-title-3","placeholder":"Tytuł 3 elementu","disabled":!_vm.form.lang},model:{value:(_vm.form.element_3.title),callback:function ($$v) {_vm.$set(_vm.form.element_3, "title", $$v)},expression:"form.element_3.title"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-form-group',{attrs:{"label":"Opis 3 elementu","label-for":"v-description-3"}},[_c('validation-provider',{attrs:{"name":"Opis 3 elementu","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-description-3","placeholder":"Opis 3 elementu","disabled":!_vm.form.lang},model:{value:(_vm.form.element_3.description),callback:function ($$v) {_vm.$set(_vm.form.element_3, "description", $$v)},expression:"form.element_3.description"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-form-group',[_c('b-form-checkbox',{model:{value:(_vm.form.element_3.is_external_link),callback:function ($$v) {_vm.$set(_vm.form.element_3, "is_external_link", $$v)},expression:"form.element_3.is_external_link"}},[_vm._v(" Link do zewnętrznej strony? ")])],1),_c('b-form-group',{attrs:{"label":"Link 3 elementu","label-for":"v-link-3"}},[_c('validation-provider',{attrs:{"name":"Link 3 elementu","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-link-3","placeholder":"Link 3 elementu","disabled":!_vm.form.lang},model:{value:(_vm.form.element_3.link),callback:function ($$v) {_vm.$set(_vm.form.element_3, "link", $$v)},expression:"form.element_3.link"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-form-group',{attrs:{"label":"Zdjęcie 3 elementu","label-for":"v-image"}},[_c('b-button',{attrs:{"disabled":!_vm.form.lang},on:{"click":function($event){return _vm.toggleShowModal('element_3_image')}}},[_vm._v(" Wybierz zdjęcie ")]),_c('validation-provider',{attrs:{"name":"Zdjęcie 3 elementu","rules":"required|size:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.files.element_3_image)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.element_3_image.file),expression:"files.element_3_image.file"}],attrs:{"type":"hidden","name":"Zdjęcie 3 elementu"},domProps:{"value":(_vm.files.element_3_image.file)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files.element_3_image, "file", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.element_3_image),expression:"files.element_3_image"}],attrs:{"type":"hidden","name":"Zdjęcie 3 elementu"},domProps:{"value":(_vm.files.element_3_image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files, "element_3_image", $event.target.value)}}}),(_vm.showImagesModal.element_3_image)?_c('ImageSelector',{attrs:{"files":_vm.filesArray,"show-modal":_vm.showImagesModal.element_3_image},on:{"closeModal":function($event){return _vm.toggleShowModal('element_3_image')},"submit":function (fileList) { return _vm.handleFile(fileList[0], 'element_3_image'); }}}):_vm._e(),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.files.element_3_image)?_c('ImagePreview',{attrs:{"image":_vm.files.element_3_image},on:{"deleteImage":function () { return _vm.handleDelete('element_3_image'); }}}):_vm._e()],1)],1),_c('b-col',{staticClass:"mt-5"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary","disabled":!this.form.lang.lang}},[_vm._v(" Zapisz ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }