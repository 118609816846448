<template>
  <b-card>
    <form-component :page="forDesignersData" @onSubmit="onSubmit" @fetchByLocale="fetchForDesignersData" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/pages/for-designers/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { show, submit } from '@/http/for-designers-homepage';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      forDesignersData: {
        title: '',
        element_1: {
          title: '',
          link: '',
          description: '',
          image: '',
          is_external_link: false,
        },
        element_2: {
          title: '',
          link: '',
          description: '',
          image: '',
          is_external_link: false,
        },
        element_3: {
          title: '',
          link: '',
          description: '',
          image: '',
          is_external_link: false,
        },
      },
    };
  },

  methods: {
    fetchForDesignersData(lang = 'pl') {
      show(lang)
        .then(async ({ data: { data } }) => {
          if (!data) return;

          this.forDesignersData = data;
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z pobraniem danych',
            'Wystąpił błąd podczas pobierania danych. Skontaktuj się ze swoim developerem.',
          );
        });
    },
    onSubmit(data) {
      const formData = new FormData();

      formData.append('lang', data.lang);
      formData.append('title', data.title);
      formData.append('element_1_title', data.element_1_title);
      formData.append('element_1_link', data.element_1_link);
      formData.append('element_1_link_is_external', data.element_1_link_is_external === true ? 1 : 0);
      formData.append('element_1_description', data.element_1_description);
      formData.append('element_1_image', data.element_1_image.file, data.element_1_image.fileName);

      formData.append('element_2_title', data.element_2_title);
      formData.append('element_2_link', data.element_2_link);
      formData.append('element_2_link_is_external', data.element_2_link_is_external === true ? 1 : 0);
      formData.append('element_2_description', data.element_2_description);
      formData.append('element_2_image', data.element_2_image.file, data.element_2_image.fileName);

      formData.append('element_3_title', data.element_3_title);
      formData.append('element_3_link', data.element_3_link);
      formData.append('element_3_link_is_external', data.element_3_link_is_external === true ? 1 : 0);
      formData.append('element_3_description', data.element_3_description);
      formData.append('element_3_image', data.element_3_image.file, data.element_3_image.fileName);

      submit(formData)
        .then(() => {
          this.showSuccessNotification('Dane zapisane', 'Strona została zapisana.');
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z zapisaniem danych',
            'Wystąpił błąd podczas zapisywania danych. Skontaktuj się ze swoim developerem.',
          );
        });
    },
  },
};
</script>
